var getTemplate, ready;
import algoliasearch from 'algoliasearch/lite';
import instantsearch from 'instantsearch.js';
import { configure, searchBox, hits, clearRefinements, currentRefinements, infiniteHits, refinementList, sortBy, stats } from 'instantsearch.js/es/widgets';

function pull_algolia_index(){
  const searchClient = algoliasearch('RRKA95FEB7', '0a7173fc951471fd46ff2645968de64d');
  portfolio_items = JSON.parse($('#portfolio_items').attr("data-portfolio-items"));
  const search = instantsearch({
    indexName: 'Artwork_' + $("body").attr("data-algolia-index"),
    routing: true,
    searchClient
  });

  search.addWidgets([
    configure({
      hitsPerPage: 24
    }),
    clearRefinements({
      container: '#clear-all',
      templates: {
        resetLabel: 'Clear'
      },
      cssClasses: {
        button: "btn btn-primary btn-sm"
      }
    }),
    infiniteHits({
      container: "#hits-container",
      templates: {
        item: getTemplate('hit')
      },
      cssClasses: {
        root: "row",
        list: 'list-unstyled d-flex align-items-stretch flex-wrap justify-content-between',
        item: "col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 pb-1 me-2",
        showmore: 'text-center mt-3'
      },
      transformItems(items) {
        return items.map(item => ({
          ...item,
          portfolio:  $.inArray(item.id, portfolio_items) > -1
        }));
      }
    }),
    refinementList({
      container: '#artists',
      attribute: 'artists.name',
      operator: 'or',
      showMore: true,
      templates: {
        label: 'Artists'
      },
      cssClasses: {
        header: "h4",
        list: 'list-unstyled',
        item: "small",
        count: "badge bg-light text-dark"
      }
    }),
    refinementList({
      container: '#bands',
      attribute: 'band.name',
      operator: 'or',
      showMore: true,
      templates: {
        header: 'Bands'
      },
      cssClasses: {
        header: "h4",
        list: 'list-unstyled',
        item: "small",
        count: "badge bg-light text-dark"
      }
    }),
    refinementList({
      container: '#series',
      attribute: 'in_series?',
      operator: 'or',
      showMore: true,
      templates: {
        header: 'In Series'
      },
      cssClasses: {
        header: "h4",
        list: 'list-unstyled',
        item: "small",
        count: "badge bg-light text-dark"
      }
    }),
    refinementList({
      container: '#variants',
      attribute: 'has_variants?',
      operator: 'or',
      showMore: true,
      templates: {
        header: 'Has Variants'
      },
      cssClasses: {
        header: "h4",
        list: 'list-unstyled',
        item: "small",
        count: "badge bg-light text-dark"
      }
    }),
    refinementList({
      container: '#years',
      attribute: 'release_date_year',
      operator: 'or',
      sortBy: ['name:desc'],
      showMore: true,
      templates: {
        header: 'Years'
      },
      cssClasses: {
        header: "h4",
        list: 'list-unstyled',
        item: "small",
        count: "badge bg-light text-dark"
      }
    }),
    searchBox({
      autofocus: true,
      container: '#search-container',
      placeholder: 'Search for artwork...',
      showSubmit: false,
      showReset: false,
      cssClasses: {
        input: "form-control rounded-0"
      }
    }),
    sortBy({
      container: '#sort-by-selector-container',
      items: [
        {
          value: 'Artwork_' + $("body").attr("data-algolia-index"),
          label: 'Featured'
        },
        {
          value: 'Artwork_' + $("body").attr("data-algolia-index") + '_by_artist_asc',
          label: 'By Artist Asc'
        },
        {
          value: 'Artwork_' + $("body").attr("data-algolia-index") + '_by_artist',
          label: 'By Artist Desc'
        },
        {
          value: 'Artwork_' + $("body").attr("data-algolia-index") + '_by_release_date_asc',
          label: 'By Date Asc'
        },
        {
          value: 'Artwork_' + $("body").attr("data-algolia-index") + '_by_release_date',
          label: 'By Date Desc'
        }
      ],
      label: 'sort by'
    }),
    stats({
      container: '#stats-container'
    })
  ]),
  search.start();
}

getTemplate = function(templateName) {
  return document.getElementById(templateName + '-template').innerHTML;
};
$( document ).on('turbolinks:load', function() {
  var element =  document.getElementById('algolia-search');
  // Check to see if the algolia search container is there and if so run the search
  if (typeof(element) != 'undefined' && element != null)
    {
      pull_algolia_index();
    }
})
