import mapboxgl from '!mapbox-gl';

function get_map(){
  console.log("called the get_map function");
  mapboxgl.accessToken = 'pk.eyJ1IjoicnRheWxvcjAxIiwiYSI6ImNrc2RvdmNpZjBzZTMydm52cmpvcHllOWEifQ.KaCXK0qytUIRF0042GtpFA';
  var longlat = $('#map').attr('data-longlat').split(" ");
  var map = new mapboxgl.Map({
    container: 'map',
    style: 'mapbox://styles/mapbox/streets-v11',
    center: longlat,
    zoom: 9
  });
  map.addControl(new mapboxgl.NavigationControl());
  var marker = new mapboxgl.Marker()
    .setLngLat(longlat)
    .addTo(map);
  }


$( document ).on('turbolinks:load', function() {
  var element =  document.getElementById('map');
  // Check to see if the algolia search container is there and if so run the search
  if (typeof(element) != 'undefined' && element != null)
    {
      get_map();
    }
})
